export const layout = {
    Appmain : {
        display: "flex",
        justifyContent: 'center',
        alignItems: "flex-start",
        flexWrap: "wrap",
        backgroundColor: "#f9f9f7",
        height: "100vh",
        width: "100vw",
    },

    celebration : {
        display: "flex",
        justifyContent: 'center',
        alignItems: "flex-start",
        flexWrap: "wrap",
        backgroundColor: "#f9f9f7",
        height: "100vh",
        width: "100vw",
    },

    titleArea : {
        position: "relative",
        display: 'flex',
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: 'center',
        // border: "1px solid #000",
        height: "130px",
    },

    displayArea : {
        display: 'flex',
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: 'center',
        maxWidth: "810px",
        border: "3px solid #000",
        borderRadius: "25px",
    },

    playArea : {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        // border: "1px solid #000",
        maxWidth: "804px",
    },

    canvasContainer: {
        position: "relative",
        width: "600px",
        height: "600px",
        order: 1,
        // border: "1px solid #000",
        marginLeft: "auto",
        marginRight: "auto",
    },
    
    canvas : {
        width: "600px",
        height: "600px",
    },

    buttonsContainer: {
        position: "relative",
        order: 2,
        // border: "1px solid #000",
        width: "200px",
        height: "600px",
        marginLeft: "auto",
        marginRight: "auto",
    },

    levelSelectContainer: {
        position: "relative",
        order: 3,
        // border: "1px solid #000",
        width: "800px",
        height: "100px",
        marginLeft: "auto",
        marginRight: "auto",
    },

    howToPlayContainer : {
        position: "relative",
        flexDirection: "column",
        flexWrap: "wrap",
        justifyContent: 'center',
        // border: "1px solid #000",
        height: "210px",
        order: 5,
    },

    buttons : {
        position: "absolute",
        left: "12.5%",
        top: 100,
    }, 

    upbutton: {
        position: "absolute",
        left: 50,
        top: 0,
        width: '50px',
        height: '50px',
        border: "2px solid #000",
        borderRadius: "5px",
    },
      
      leftbutton: {
        position: "absolute",
        left: 0,
        top: 50,
        width: '50px',
        height: '50px',
        border: "2px solid #000",
        borderRadius: "5px",
    },
      
      rightbutton: {
        position: "absolute",
        left: 100,
        top: 50,
        width: '50px',
        height: '50px',
        border: "2px solid #000",
        borderRadius: "5px",
    },
      
      downbutton: {
        position: "absolute",
        left: 50,
        top: 100,
        width: '50px',
        height: '50px',
        border: "2px solid #000",
        borderRadius: "5px",
    },

      squarePushText : {
        position: "absolute",
        left: 260, 
        top: -35, 
        color: "black",
        width: 200,
        fontSize: "50px"
    },

    text : {
        position: "absolute",
        left: 290, 
        top: 0, 
        color: "black",
        fontSize: "18px"
    },

    flavorText1 : {
        position: "absolute",
        left: 10, 
        top: 50, 
        color: "black",
        fontSize: "16px"
    },

    flavorText2 : {
        position: "absolute",
        left: 10, 
        top: 90, 
        color: "black",
        fontSize: "16px"
    },

    flavorText3 : {
        position: "absolute",
        left: 10, 
        top: 130, 
        color: "black",
        fontSize: "16px"
    },

    flavorText4 : {
        position: "absolute",
        left: 10, 
        top: 175, 
        color: "black",
        fontSize: "16px"
    },

    goldCounter : {
        position: "absolute",
        left: 430,
        top: 70,
        color: "black",
        width: 190,
        fontSize: "20px",
        border: "2px solid #000",
        borderRadius: "5px",
    },

    moveCounter : {
        position: "absolute",
        left: 200,
        top: 70,
        color: "black",
        width: 190,
        fontSize: "20px",
        border: "2px solid #000",
        borderRadius: "5px",
    },

    resetButton : {
        position: "absolute",
        left: 15,
        top: 450,
        color: "black",
        width: 170,
        fontSize: "20px",
        border: "2px solid #000",
        borderRadius: "5px",
    },

    removeButton : {
        position: "absolute",
        left: 15,
        top: 350,
        color: "black",
        width: 170,
        fontSize: "20px",
        border: "2px solid #000",
        borderRadius: "5px",
    },

    timerText : {
        position: "absolute",
        left: 72,
        top: 35,
        color: "black",
        width: 51,
        fontSize: "20px",
        border: "2px solid #000",
        borderRadius: "5px",
    },

    playScreenLevelSelect : {
        position: "absolute",
        left: "320px",
        top: "20px",
        color: "black",
        width: 160,
        fontSize: "20px",
        border: "2px solid #000",
        borderRadius: "5px",
    },

    playLevelButton1 : {
        position: "absolute",
        left: "0px",
        top: "50px",
        color: "black",
        width: 79,
        fontSize: "20px",
        border: "2px solid #000",
    },

    playLevelButton2 : {
        position: "absolute",
        left: "80px",
        top: "50px",
        color: "black",
        width: 79,
        fontSize: "20px",
        border: "2px solid #000",
    },

    playLevelButton3 : {
        position: "absolute",
        left: "160px",
        top: "50px",
        color: "black",
        width: 79,
        fontSize: "20px",
        border: "2px solid #000",
    },

    playLevelButton4 : {
        position: "absolute",
        left: "240px",
        top: "50px",
        color: "black",
        width: 79,
        fontSize: "20px",
        border: "2px solid #000",
    },

    playLevelButton5 : {
        position: "absolute",
        left: "320px",
        top: "50px",
        color: "black",
        width: 79,
        fontSize: "20px",
        border: "2px solid #000",
    },

    playLevelButton6 : {
        position: "absolute",
        left: "400px",
        top: "50px",
        color: "black",
        width: 79,
        fontSize: "20px",
        border: "2px solid #000",
    },

    playLevelButton7 : {
        position: "absolute",
        left: "480px",
        top: "50px",
        color: "black",
        width: 79,
        fontSize: "20px",
        border: "2px solid #000",
    },

    playLevelButton8 : {
        position: "absolute",
        left: "560px",
        top: "50px",
        color: "black",
        width: 79,
        fontSize: "20px",
        border: "2px solid #000",
    },

    playLevelButton9 : {
        position: "absolute",
        left: "640px",
        top: "50px",
        color: "black",
        width: 79,
        fontSize: "20px",
        border: "2px solid #000",
    },

    playLevelButton10 : {
        position: "absolute",
        left: "720px",
        top: "50px",
        color: "black",
        width: 79,
        fontSize: "20px",
        border: "2px solid #000",
    },


    congratsNinjaSe : {
        position: "absolute",
        left: "600px",
        top: "200px",
    },

    congratsMessage : {
        position: "absolute",
        left: "500px",
        top: "80px",
        width: 800,
        fontSize: "70px"
    },

    congratsButton : {
        position: "absolute",
        left: "700px",
        top: "650px",
        color: "black",
        backgroundColor: "#D3D3D3",
        width: 200,
        fontSize: "30px"
    }, 

    congratsEmojiLeft : {
        position: "absolute",
        left: "100px",
        top: "200px",
    },

    congratsEmojiRight : {
        position: "absolute",
        left: "1100px",
        top: "200px",
    },


}
import React from 'react';
import { lvl_1, lvl_2, lvl_3, lvl_4, lvl_5, lvl_6, lvl_7, lvl_8, lvl_9, lvl_10 } from './model/Levels.js'
import { redrawCanvas } from './boundary/Boundary.js'
import { Model } from './model/Model.js'
import { layout } from './Layout.js'
import ninjaSe from './ninjaSe.png'
import './App.css'
import AdSenseComponent from './AdSenseComponent';

function App() {
  const levels = [lvl_1, lvl_2, lvl_3, lvl_4, lvl_5, lvl_6, lvl_7, lvl_8, lvl_9, lvl_10];
  const [model, setModel] = React.useState(new Model(levels[0]));
  const [redraw, forceRedraw] = React.useState(0);
  const [playingGame, setPlayingGame] = React.useState(false);
  const [gameNotFinished, setgameNotFinished] = React.useState(true);
  const [isDropdownOpen, setDropdownOpen] = React.useState(false);
  const appRef = React.useRef(null);
  const canvasRef = React.useRef(null);
  const [gold, setGold] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);
  const [minutes, setMinutes] = React.useState(0);
  var timer;

  React.useEffect(() => {

    redrawCanvas(model, canvasRef.current, appRef.current)
  }, [model, redraw])

  // Move NinjaSe Controller
  const moveNinjaSe = (direction) => {
    if (direction == 'up') {
      if (model.ninja.row > 0) {
        let newModel = model.copy()
        newModel.ninja.row = model.ninja.row - 1
        newModel.numMoves += 1
        let pushCells = []
        if (newModel.puzzle.cells[newModel.ninja.row][newModel.ninja.col].color !== null) {
          pushCells.push(newModel.puzzle.cells[newModel.ninja.row][newModel.ninja.col])
        }
        if (newModel.puzzle.cells[newModel.ninja.row][newModel.ninja.col + 1].color !== null) {
          pushCells.push(newModel.puzzle.cells[newModel.ninja.row][newModel.ninja.col + 1])
        }
        pushCells.forEach(cell => {
          newModel.moveColors(cell.row, cell.col, direction)
        });
        setModel(newModel)
      }
    } else if (direction == 'left') {
      if (model.ninja.col > 0) {
        let newModel = model.copy()
        newModel.ninja.col = model.ninja.col - 1
        newModel.numMoves += 1
        let pushCells = []
        if (newModel.puzzle.cells[newModel.ninja.row][newModel.ninja.col].color !== null) {
          pushCells.push(newModel.puzzle.cells[newModel.ninja.row][newModel.ninja.col])
        }
        if (newModel.puzzle.cells[newModel.ninja.row + 1][newModel.ninja.col].color !== null) {
          pushCells.push(newModel.puzzle.cells[newModel.ninja.row + 1][newModel.ninja.col])
        }
        pushCells.forEach(cell => {
          newModel.moveColors(cell.row, cell.col, direction)
        });
        setModel(newModel)
      }
    } else if (direction == 'right') {
      if (model.ninja.col < model.level.numColumns - 2) {
        let newModel = model.copy()
        newModel.ninja.col = model.ninja.col + 1
        newModel.numMoves += 1
        let pushCells = []
        if (newModel.puzzle.cells[newModel.ninja.row][newModel.ninja.col + 1].color !== null) {
          pushCells.push(newModel.puzzle.cells[newModel.ninja.row][newModel.ninja.col + 1])
        }
        if (newModel.puzzle.cells[newModel.ninja.row + 1][newModel.ninja.col + 1].color !== null) {
          pushCells.push(newModel.puzzle.cells[newModel.ninja.row + 1][newModel.ninja.col + 1])
        }
        pushCells.forEach(cell => {
          newModel.moveColors(cell.row, cell.col, direction)
        });
        setModel(newModel)
      }
    } else if (direction == 'down') {
      if (model.ninja.row < model.level.numRows - 2) {
        let newModel = model.copy()
        newModel.ninja.row = model.ninja.row + 1
        newModel.numMoves += 1
        let pushCells = []
        if (newModel.puzzle.cells[newModel.ninja.row + 1][newModel.ninja.col].color !== null) {
          pushCells.push(newModel.puzzle.cells[newModel.ninja.row + 1][newModel.ninja.col])
        }
        if (newModel.puzzle.cells[newModel.ninja.row + 1][newModel.ninja.col + 1].color !== null) {
          pushCells.push(newModel.puzzle.cells[newModel.ninja.row + 1][newModel.ninja.col + 1])
        }
        pushCells.forEach(cell => {
          newModel.moveColors(cell.row, cell.col, direction)
        });
        setModel(newModel)
      }
    }
  }

  const resetPuzzle = (level) => {
    let newModel = new Model(level)
    setModel(newModel)
  };

  // Remove Blocks Controller
  const removeBlocks = () => {
    let newModel = model.copy()
    let result = newModel.removeBlocks()
    if (result[1]) {
      setGold(gold + 4);
    }
    if (result[0]) {
      let curLevel = model.level
      let lvl = Number(curLevel.name.match(/\d+/)?.[0])
      if (lvl < 10) {
        let newModel = new Model(levels[lvl])
        setModel(newModel)
      } else {
        togglegameNotFinished();
        let newModel = new Model(levels[0])
        setModel(newModel)
      }

    } else {
      setModel(newModel)
    }

  };

  React.useEffect(() => {
    timer = setInterval(() => {
      setSeconds(prevSeconds => {
        let newSeconds = prevSeconds + 1;
        if (newSeconds === 60) {
          setMinutes(prevMinutes => prevMinutes + 1);
          return 0;
        }
        return newSeconds;
      });
    }, 1000);
  
    return () => {
      clearInterval(timer);
    };
  }, []);

  React.useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case 'ArrowUp':
        case 'W':
        case 'w':
          moveNinjaSe('up');
          break;
        case 'ArrowLeft':
        case 'A':
        case 'a':
          moveNinjaSe('left');
          break;
        case 'ArrowRight':
        case 'D':
        case 'd':
          moveNinjaSe('right');
          break;
        case 'ArrowDown':
        case 'S':
        case 's':
          moveNinjaSe('down');
          break;
        case 'Enter':
          removeBlocks();
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [moveNinjaSe, removeBlocks]);

  const togglegameNotFinished = () => {
    setgameNotFinished(!gameNotFinished);
  };

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const selectLevel = (level) => {
    let newModel = new Model(levels[level])
    setModel(newModel)
    setDropdownOpen(!isDropdownOpen);
  };


  return (
    <main style={layout.Appmain} ref={appRef}>
      <div hidden={!gameNotFinished}>
        <div style={layout.displayArea}>
          <div style={layout.titleArea}>
            <h1 style={layout.squarePushText}>
              SquarePush
            </h1>
            <label style={layout.moveCounter}>Move Counter: {model.numMoves}</label>
            <label style={layout.goldCounter}>Gold Counter: {gold}</label>
          </div>
          <div style={layout.playArea}>
            <div style={layout.canvasContainer}>
              {/* Canvas */}
              <canvas tabIndex="1"
                className="App-canvas"
                ref={canvasRef}
                width={layout.canvas.width}
                height={layout.canvas.height}
                style={layout.canvas} />

              {/* NinjaSe Image */}
              <div style={{ position: 'absolute', top: model.ninja.row * 100, left: model.ninja.col * 100 }}>
                <img src={require('./ninjaSe.png')} style={{ width: '200px', height: '200px' }} onDragStart={(e) => e.preventDefault()} />
              </div>
            </div>

            {/* Buttons */}
            <div style={layout.buttonsContainer}>
              <div style={layout.buttons}>
                <button style={layout.upbutton} onClick={(e) => moveNinjaSe('up')} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>^</button>
                <button style={layout.leftbutton} onClick={(e) => moveNinjaSe('left')} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>&lt;</button>
                <button style={layout.rightbutton} onClick={(e) => moveNinjaSe('right')} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>&gt;</button>
                <button style={layout.downbutton} onClick={(e) => moveNinjaSe('down')} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>v</button>
              </div>
              <span style={layout.timerText}>
                {minutes < 10 ? "0" + minutes : minutes}:{seconds < 10 ? "0" + seconds : seconds}
              </span>
              <button style={layout.resetButton} onClick={(e) => resetPuzzle(model.level)} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>Reset Puzzle</button>
              <button style={layout.removeButton} onClick={(e) => removeBlocks()} onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}>Remove Blocks</button>
            </div>
            <div style={layout.levelSelectContainer}>
              <button style={layout.playScreenLevelSelect} onClick={toggleDropdown}>{'Level: ' + model.level.name.match(/\d+/)?.[0]}</button>
              {isDropdownOpen && (
                <div>
                  <button style={layout.playLevelButton1} onClick={(e) => selectLevel(0)}>1</button>
                  <button style={layout.playLevelButton2} onClick={(e) => selectLevel(1)}>2</button>
                  <button style={layout.playLevelButton3} onClick={(e) => selectLevel(2)}>3</button>
                  <button style={layout.playLevelButton4} onClick={(e) => selectLevel(3)}>4</button>
                  <button style={layout.playLevelButton5} onClick={(e) => selectLevel(4)}>5</button>
                  <button style={layout.playLevelButton6} onClick={(e) => selectLevel(5)}>6</button>
                  <button style={layout.playLevelButton7} onClick={(e) => selectLevel(6)}>7</button>
                  <button style={layout.playLevelButton8} onClick={(e) => selectLevel(7)}>8</button>
                  <button style={layout.playLevelButton9} onClick={(e) => selectLevel(8)}>9</button>
                  <button style={layout.playLevelButton10} onClick={(e) => selectLevel(9)}>10</button>
                </div>
              )}
            </div>
          </div>
          <div style={layout.howToPlayContainer}>
            <h3 style={layout.text}>
              How to play SquarePush
            </h3>
            <span style={layout.flavorText1}>
              Move NinjaSe around the grid by using WASD, Arrow Keys, or by clicking the buttons.
            </span>
            <span style={layout.flavorText2}>
              As NinjaSe moves, he will push squares that are adjacent to him.
            </span>
            <span style={layout.flavorText3}>
              Once the squares are in a 2x2 shape, press Enter Key or click the remove squares button to remove the squares from the puzzle.
            </span>
            <span style={layout.flavorText4}>
              Remove all the squares from the puzzle to advance to the next level!
            </span>
          </div>
        </div>
      </div>
      <div hidden={gameNotFinished}>
        <h1>
          Congratulations! You've won the Game!
        </h1>
      </div>
    </main>
  );
}

export default App;

export class Cell {
    constructor(row, col, color) {
        this.row = row
        this.col = col
        this._color = color
    }

    // Getter method for the block property
    get color() {
        return this._color
    }

    // Setter method for the block property
    set color(newColor) {
        this._color = newColor
    }

    copy() {
        let c = new Cell(this.row, this.col, this._color)
        return c
    }
}

export class Puzzle {
    constructor(nr, nc, level) {
        this.nr = nr
        this.nc = nc
        this.level = level

        // this is where you would create the nr x nc Cell objects that you need.
        // OPTION 1: Create what looks like a 2D array this.cells[R][C]
        this.cells = []
        for (let r = 0; r < nr; r++) { 
            this.cells[r] = []; 
            for (let c = 0; c < nc; c++) {
                this.cells[r][c] = new Cell(r, c, null)
            }
        }
        
        if (typeof(level) !== "undefined") {
            for (let i = 0; i < this.level.initial.length; i++) {

                // Subtracting 1 from row so that row vals range from 0 -> 4 inclusive
                let row = this.level.initial[i].row - 1
    
                // Taking charCodeAt(0) and subtracting 65 to Map A, B, C to 0, 1, 2 etc.
                let col = this.level.initial[i].column.charCodeAt(0) - 65
    
                // Taking color as is
                let color = this.level.initial[i].color

                this.cells[row][col].color = color
                
            }
        }
    }

    copy() {
        let copiedCells = this.cells.map(row => row.map(cell => cell.copy()))
      
        let p = new Puzzle(this.nr, this.nc, this.level)
        p.cells = copiedCells;
        
        return p
    }

    moveColors(row, col, direction) {

        let cell = this.cells[row][col];
        let oldColor = cell.color;

        if (direction == 'up') {
            if (row > 0) {
                if (this.cells[row-1][col].color !== null) {
                    this.moveColors(row-1, col, direction)
                }
                this.cells[row-1][col].color = oldColor
            } else {
                if (this.cells[this.nr-1][col].color !== null) {
                    this.moveColors(this.nr-1, col, direction)
                }
                this.cells[this.nr-1][col].color = oldColor
            }

        } else if (direction == 'left') {
            if (col > 0) {
                if (this.cells[row][col-1].color !== null) {
                    this.moveColors(row, col-1, direction)
                }
                this.cells[row][col-1].color = oldColor
            } else {
                if (this.cells[row][this.nc-1].color !== null) {
                    this.moveColors(row, this.nc-1, direction)
                }
                this.cells[row][this.nc-1].color = oldColor
            }

        } else if (direction == 'right') {
            if (col < this.nc-1) {
                if (this.cells[row][col+1].color !== null) {
                    this.moveColors(row, col+1, direction)
                }
                this.cells[row][col+1].color = oldColor
            } else {
                if (this.cells[row][0].color !== null) {
                    this.moveColors(row, 0, direction)
                }
                this.cells[row][0].color = oldColor
            }
            
        } else if (direction == 'down') {
            if (row < this.nr-1) {
                if (this.cells[row+1][col].color !== null) {
                    this.moveColors(row+1, col, direction)
                }
                this.cells[row+1][col].color = oldColor
            } else {
                if (this.cells[0][col].color !== null) {
                    this.moveColors(0, col, direction)
                }
                this.cells[0][col].color = oldColor
            }
        }
        cell.color = null;
    }

    removeBlocks() {
        const nr = this.nr;
        const nc = this.nc;

        let removed = false;
    
        // Remove blocks
        for (let r = 0; r < nr-1; r++) {
            for (let c = 0; c < nc-1; c++) {

                if (!removed) {
                    const group = [this.cells[r][c], this.cells[r + 1][c], this.cells[r][c + 1], this.cells[r + 1][c + 1]];

                    const hasSameColor = group.every(cell => cell !== null && cell.color === group[0].color && group[0].color !== null);
        
                    if (hasSameColor && !removed) {
                        group.forEach(cell => {
                            cell.color = null;
                        });
                        removed = true;
                    }
                }
            }
        }

        // Check if the puzzle is solved
        let solved = true
        for (let r = 0; r < nr; r++) {
            for (let c = 0; c < nc; c++) {
                if (this.cells[r][c].color !== null) {
                    solved = false
                }
            }
        }
        return [solved, removed]
    }
}

class block {
    constructor(row, col, color) {
        this._row = row
        this._col = col
        this._color = color
    }

    get row() {
        return this._row
    }

    set row(value) {
        this._row = value
    }

    get col() {
        return this._col
    }

    set col(value) {
        this._col = value
    }

    get color() {
        return this._color
    }

    set color(value) {
        this._color = value
    }

    copy() {
        let b = new block(this.row, this.col, this.color)
        return b
    }
}

class ninjaSe {
    constructor(row, col) {
        this.row = row
        this.col = col
    }

    copy() {
        let n = new ninjaSe(this.row, this.col)
        return n
    }
}

// Model knows the level (you need 3). Knows the Puzzle
export class Model {
    constructor(level) {
        this.level = level;
        this.numMoves = 0;
        this.score = 0;

        if (typeof(level) !== "undefined") {
            let nr = level.numRows;
            let nc = level.numColumns;

            // Add Ninja-Se to the Model
            this.ninja = new ninjaSe(this.level.ninjaRow-1, this.level.ninjaColumn.charCodeAt(0)-65);

            this.puzzle = new Puzzle(nr, nc, this.level);
        }
    }

    copy() {
        let m = new Model();
        m.level = this.level;
        m.nr = this.nr;
        m.nc = this.nc;
        m.numMoves = this.numMoves;
        m.score = this.score;
        m.ninja = this.ninja.copy();
        m.puzzle = this.puzzle.copy();
        return m;
      }

    moveColors(row, col, direction) {
        this.puzzle.moveColors(row, col, direction);
    }

    removeBlocks() {
        const result = this.puzzle.removeBlocks();
        const solved = result[0];
        const removed = result[1];
        if (removed) {
            this.numMoves += 1;
            this.score += 4;
        }
        return [solved, removed]
    }
}
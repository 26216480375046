
// Scaling Constants for Canvas
var BOXSIZE = 100;
const OFFSET = 0;

/** Represents a rectangle. */
export class Square {
    constructor(x, y, size, color) {
      this.x = x
      this.y = y
      this.size = size
      this.color = color
    }
}

export function computeSquare(cell) {
    if (cell.color != null) {
        return new Square(BOXSIZE*cell.col + OFFSET, BOXSIZE*cell.row + OFFSET, BOXSIZE - 2*OFFSET, cell.color)
    } else {
        return new Square(BOXSIZE*cell.col + OFFSET, BOXSIZE*cell.row + OFFSET, BOXSIZE - 2*OFFSET, null)
    }
}


/** Redraw entire canvas from model. */
export function redrawCanvas(model, canvasObj) {
    const ctx = canvasObj.getContext('2d');
    ctx.clearRect( 0,0, canvasObj.width, canvasObj.height);  
   
    // showing the outermost information
    let nr = model.puzzle.nr
    let nc = model.puzzle.nc

    for (let r = 0; r < nr; r++) {
        for (let c = 0; c < nc; c++) {
            let cell = model.puzzle.cells[r][c]
            let sq = computeSquare(cell)

            // HERE is where you draw everything about this cell that you know about...
            ctx.beginPath()

            ctx.strokeStyle = 'black'
            ctx.rect(sq.x, sq.y, sq.size, sq.size)
            ctx.stroke()

            if (sq.color !== null) {
                ctx.fillStyle = sq.color
                ctx.fillRect((sq.x + 1), (sq.y + 1), (sq.size-2), (sq.size-2))
            }
        }
    }
}
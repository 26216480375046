export const lvl_1 = {
    "name": "Level 1",
    "numRows" : "5",
    "numColumns" : "5",
    "ninjaRow" : "4",
    "ninjaColumn": "D",
    "initial" : [
        { "color" : "#ff6961", "row": "1", "column" : "D" },
        { "color" : "#ff6961", "row": "1", "column" : "E" },
        { "color" : "#ff6961", "row": "3", "column" : "D" },
        { "color" : "#ff6961", "row": "3", "column" : "E" },
      
        { "color" : "#ffb480", "row": "2", "column" : "A" },
        { "color" : "#ffb480", "row": "2", "column" : "C" },
        { "color" : "#ffb480", "row": "3", "column" : "A" },
        { "color" : "#ffb480", "row": "3", "column" : "C" },

        { "color" : "#59adf6", "row": "4", "column" : "A" },
        { "color" : "#59adf6", "row": "4", "column" : "C" },
        { "color" : "#59adf6", "row": "5", "column" : "A" },
        { "color" : "#59adf6", "row": "5", "column" : "C" }
    ]
}

export const lvl_2 = {
    "name": "Level 2",
    "numRows": "5",
    "numColumns": "5",
    "ninjaRow": "1",
    "ninjaColumn": "D",
    "initial": [
        { "color" : "#ff6961", "row": "1", "column": "B" },
        { "color" : "#ff6961", "row": "4", "column": "C" },
        { "color" : "#ff6961", "row": "2", "column": "B" },
        { "color" : "#ff6961", "row": "5", "column": "C" },

        { "color" : "#42d6a4", "row": "1", "column": "C" },
        { "color" : "#42d6a4", "row": "4", "column": "D" },
        { "color" : "#42d6a4", "row": "2", "column": "C" },
        { "color" : "#42d6a4", "row": "5", "column": "D" }
    ]
}

export const lvl_3 = {
    "name": "Level 3",
    "numRows" : "4",
    "numColumns" : "4",
    "ninjaRow" : "2",
    "ninjaColumn": "B",
    "initial" : [
        { "color" : "#08cad1", "row": "1", "column" : "C" },
        { "color" : "#08cad1", "row": "2", "column" : "D" },
        { "color" : "#08cad1", "row": "3", "column" : "A" },
        { "color" : "#08cad1", "row": "4", "column" : "C" }
    ]
}

export const lvl_4 = {
    "name": "Level 4",
    "numRows" : "6",
    "numColumns" : "6",
    "ninjaRow" : "1",
    "ninjaColumn": "C",
    "initial" : [
        { "color" : "#ec7014", "row": "1", "column" : "E" },
        { "color" : "#ec7014", "row": "4", "column" : "A" },
        { "color" : "#ec7014", "row": "3", "column" : "B" },
        { "color" : "#ec7014", "row": "5", "column" : "D" },
     
        { "color" : "#f6a437", "row": "2", "column" : "A" },
        { "color" : "#f6a437", "row": "3", "column" : "A" },
        { "color" : "#f6a437", "row": "5", "column" : "A" },
        { "color" : "#f6a437", "row": "5", "column" : "F" },

        { "color" : "#ffd366", "row": "3", "column" : "F" },
        { "color" : "#ffd366", "row": "4", "column" : "F" },
        { "color" : "#ffd366", "row": "4", "column" : "B" },
        { "color" : "#ffd366", "row": "6", "column" : "F" },

        { "color" : "#758a9b", "row": "6", "column" : "A" },
        { "color" : "#758a9b", "row": "1", "column" : "A" },
        { "color" : "#758a9b", "row": "6", "column" : "C" },
        { "color" : "#758a9b", "row": "6", "column" : "D" },

        { "color" : "#b0b0b0", "row": "6", "column" : "B" },
        { "color" : "#b0b0b0", "row": "4", "column" : "D" },
        { "color" : "#b0b0b0", "row": "5", "column" : "B" },
        { "color" : "#b0b0b0", "row": "5", "column" : "C" }
    ]
}

export const lvl_5 = {
    "name": "Level 5",
    "numRows" : "4",
    "numColumns" : "4",
    "ninjaRow" : "2",
    "ninjaColumn": "B",
    "initial" : [
        { "color" : "#f8f38d", "row": "1", "column" : "C" },
        { "color" : "#f8f38d", "row": "1", "column" : "B" },
        { "color" : "#f8f38d", "row": "3", "column" : "A" },
        { "color" : "#f8f38d", "row": "4", "column" : "C" },

        { "color" : "#9d94ff", "row": "1", "column" : "A" },
        { "color" : "#9d94ff", "row": "2", "column" : "D" },
        { "color" : "#9d94ff", "row": "3", "column" : "D" },
        { "color" : "#9d94ff", "row": "4", "column" : "B" },
    ]
}

export const lvl_6 = {
    "name": "Level 6",
    "numRows" : "5",
    "numColumns" : "5",
    "ninjaRow" : "1",
    "ninjaColumn": "A",
    "initial" : [
        { "color" : "lightgray", "row": "1", "column" : "D" },
        { "color" : "lightgray", "row": "1", "column" : "E" },
        { "color" : "lightgray", "row": "3", "column" : "D" },
        { "color" : "lightgray", "row": "2", "column" : "C" },
      
        { "color" : "gray", "row": "2", "column" : "D" },
        { "color" : "gray", "row": "3", "column" : "E" },
        { "color" : "gray", "row": "3", "column" : "A" },
        { "color" : "gray", "row": "5", "column" : "A" },

        { "color" : "darkgray", "row": "4", "column" : "A" },
        { "color" : "darkgray", "row": "5", "column" : "E" },
        { "color" : "darkgray", "row": "3", "column" : "C" },
        { "color" : "darkgray", "row": "5", "column" : "C" }
    ]
}

export const lvl_7 = {
    "name": "Level 7",
    "numRows" : "6",
    "numColumns" : "6",
    "ninjaRow" : "1",
    "ninjaColumn": "C",
    "initial" : [
        { "color" : "#ff6961", "row": "1", "column" : "E" },
        { "color" : "#ff6961", "row": "4", "column" : "A" },
        { "color" : "#ff6961", "row": "5", "column" : "A" },
        { "color" : "#ff6961", "row": "5", "column" : "D" },

        { "color" : "#ffb480", "row": "1", "column" : "B" },
        { "color" : "#ffb480", "row": "2", "column" : "B" },
        { "color" : "#ffb480", "row": "5", "column" : "E" },
        { "color" : "#ffb480", "row": "6", "column" : "E" },

        { "color" : "#f8f38d", "row": "1", "column" : "F" },
        { "color" : "#f8f38d", "row": "2", "column" : "E" },
        { "color" : "#f8f38d", "row": "3", "column" : "D" },
        { "color" : "#f8f38d", "row": "4", "column" : "C" },
     
        { "color" : "#08cad1", "row": "2", "column" : "A" },
        { "color" : "#08cad1", "row": "3", "column" : "A" },
        { "color" : "#08cad1", "row": "3", "column" : "B" },
        { "color" : "#08cad1", "row": "4", "column" : "B" },

        { "color" : "#c780e8", "row": "2", "column" : "F" },
        { "color" : "#c780e8", "row": "3", "column" : "C" },
        { "color" : "#c780e8", "row": "3", "column" : "E" },
        { "color" : "#c780e8", "row": "4", "column" : "E" },

        { "color" : "#42d6a4", "row": "3", "column" : "F" },
        { "color" : "#42d6a4", "row": "4", "column" : "F" },
        { "color" : "#42d6a4", "row": "5", "column" : "F" },
        { "color" : "#42d6a4", "row": "6", "column" : "F" },

        { "color" : "#9d94ff", "row": "6", "column" : "A" },
        { "color" : "#9d94ff", "row": "6", "column" : "B" },
        { "color" : "#9d94ff", "row": "6", "column" : "C" },
        { "color" : "#9d94ff", "row": "6", "column" : "D" },

        { "color" : "#59adf6", "row": "1", "column" : "A" },
        { "color" : "#59adf6", "row": "4", "column" : "D" },
        { "color" : "#59adf6", "row": "5", "column" : "B" },
        { "color" : "#59adf6", "row": "5", "column" : "C" }
    ]
}

export const lvl_8 = {
    "name": "Level 8",
    "numRows" : "5",
    "numColumns" : "5",
    "ninjaRow" : "4",
    "ninjaColumn": "D",
    "initial" : [
        { "color" : "#173f5f", "row": "1", "column" : "D" },
        { "color" : "#173f5f", "row": "3", "column" : "C" },
        { "color" : "#173f5f", "row": "1", "column" : "B" },
        { "color" : "#173f5f", "row": "5", "column" : "B" },
      
        { "color" : "#20639b", "row": "2", "column" : "A" },
        { "color" : "#20639b", "row": "2", "column" : "C" },
        { "color" : "#20639b", "row": "5", "column" : "A" },
        { "color" : "#20639b", "row": "1", "column" : "E" },

        { "color" : "#3caea3", "row": "4", "column" : "B" },
        { "color" : "#3caea3", "row": "4", "column" : "C" },
        { "color" : "#3caea3", "row": "3", "column" : "A" }, 
        { "color" : "#3caea3", "row": "2", "column" : "B" },

        { "color" : "#f6d55c", "row": "1", "column" : "A" },
        { "color" : "#f6d55c", "row": "3", "column" : "D" },
        { "color" : "#f6d55c", "row": "1", "column" : "C" },
        { "color" : "#f6d55c", "row": "5", "column" : "C" },

        { "color" : "#ed553b", "row": "2", "column" : "D" },
        { "color" : "#ed553b", "row": "3", "column" : "B" },
        { "color" : "#ed553b", "row": "4", "column" : "A" },
        { "color" : "#ed553b", "row": "3", "column" : "E" },
    ]
}

export const lvl_9 = {
    "name": "Level 9",
    "numRows" : "4",
    "numColumns" : "4",
    "ninjaRow" : "2",
    "ninjaColumn": "B",
    "initial" : [
        { "color" : "#ffd166", "row": "1", "column" : "C" },
        { "color" : "#ffd166", "row": "2", "column" : "D" },
        { "color" : "#ffd166", "row": "3", "column" : "A" },
        { "color" : "#ffd166", "row": "4", "column" : "C" },

        { "color" : "#06d6a0", "row": "1", "column" : "A" },
        { "color" : "#06d6a0", "row": "1", "column" : "B" },
        { "color" : "#06d6a0", "row": "3", "column" : "D" },
        { "color" : "#06d6a0", "row": "4", "column" : "D" },

        { "color" : "#118ab2", "row": "1", "column" : "D" },
        { "color" : "#118ab2", "row": "2", "column" : "A" },
        { "color" : "#118ab2", "row": "4", "column" : "A" },
        { "color" : "#118ab2", "row": "4", "column" : "B" } 
    ]
}

export const lvl_10 = {
    "name": "Level 10",
    "numRows" : "6",
    "numColumns" : "6",
    "ninjaRow" : "1",
    "ninjaColumn": "C",
    "initial" : [
        { "color" : "#ff004D", "row": "1", "column" : "E" },
        { "color" : "#ff004D", "row": "4", "column" : "F" },
        { "color" : "#ff004D", "row": "5", "column" : "A" },
        { "color" : "#ff004D", "row": "5", "column" : "D" },

        { "color" : "#ffA300", "row": "1", "column" : "B" },
        { "color" : "#ffA300", "row": "6", "column" : "A" },
        { "color" : "#ffA300", "row": "5", "column" : "C" },
        { "color" : "#ffA300", "row": "6", "column" : "E" },

        { "color" : "#ffEC27", "row": "1", "column" : "F" },
        { "color" : "#ffEC27", "row": "2", "column" : "E" },
        { "color" : "#ffEC27", "row": "3", "column" : "D" },
        { "color" : "#ffEC27", "row": "4", "column" : "C" },
     
        { "color" : "#00E43b", "row": "2", "column" : "A" },
        { "color" : "#00E43b", "row": "3", "column" : "E" },
        { "color" : "#00E43b", "row": "6", "column" : "C" },
        { "color" : "#00E43b", "row": "4", "column" : "B" },

        { "color" : "#29ADFF", "row": "2", "column" : "F" },
        { "color" : "#29ADFF", "row": "3", "column" : "C" },
        { "color" : "#29ADFF", "row": "3", "column" : "A" },
        { "color" : "#29ADFF", "row": "4", "column" : "E" },

        { "color" : "#83769C", "row": "3", "column" : "F" },
        { "color" : "#83769C", "row": "4", "column" : "A" },
        { "color" : "#83769C", "row": "5", "column" : "F" },
        { "color" : "#83769C", "row": "3", "column" : "B" },

        { "color" : "#FF77AB", "row": "2", "column" : "B" },
        { "color" : "#FF77AB", "row": "6", "column" : "B" },
        { "color" : "#FF77AB", "row": "6", "column" : "F" },
        { "color" : "#FF77AB", "row": "6", "column" : "D" },

        { "color" : "#FFCCAA", "row": "1", "column" : "A" },
        { "color" : "#FFCCAA", "row": "4", "column" : "D" },
        { "color" : "#FFCCAA", "row": "5", "column" : "B" },
        { "color" : "#FFCCAA", "row": "5", "column" : "E" }
    ]
}
  